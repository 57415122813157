import React from 'react';
import '../stylesheets/Dropdown.css';

const Dropdown = ({ options, label, onChange }) => {

  const handleChange = (event) => {
    // Llama a la función onChange pasando el valor seleccionado
    onChange(event.target.value);
  };

  return (
    <div className="dropdown-container">
      <select 
        className='dropdown-select'
        onChange={handleChange}
      >
      <option value="" disabled selected hidden>{label}</option>
        {
          options 
            ? options.map((provincia) => {
                return (
                  <option 
                    key={provincia.IteDinCod} 
                    className='dropdown-option'
                    value={provincia.IteDinCod}>
                    {provincia.IteDinDes}
                  </option>
                );
              })
            : null
        }
      </select>
    </div>
  );
};

export default Dropdown;
