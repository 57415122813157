import React from 'react';
import '../stylesheets/BotonRedSocial.css';

function BotonRedSocial ({ imagenSrc, enlace, alt}) {
  return (
    <a href={enlace} target="_blank" rel="noopener noreferrer" className="btn-red-social">
      <img src={imagenSrc} alt={alt} />
    </a>
  );
};

export default BotonRedSocial;