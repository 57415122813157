import React from "react";
import '../stylesheets/HeaderPasos.css';
import imageSrc from '../media/puntos 1.svg'

function MostrarTitulo({ textoTitulo, paso }) {
  return (
        <div className="contenedor-titulo-paso-2">
          <p className="titulo-paso-2">
            <span className="palabra-cotizador">COTIZADOR: </span> 
            <span className="nombre-seguro">{textoTitulo}</span>
          </p>
          {paso === 2 ? (
            <p className="subtitulo-paso-2">COMPLETÁ TUS DATOS ({paso}/3)</p>
          ) : paso === 3 ? (
            <p className="subtitulo-paso-2">COTIZACIÓN ({paso}/3)</p>
          ) : null}
      </div>
  );
}

export default MostrarTitulo;
