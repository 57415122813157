import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';

const useGetCoberturas = (EmpCodPlanes, ProTipCodPlanes, 
  TabCodPlanes, TabCodCelPlanes, AseCod) => {
    
  const {token, loadingToken, client} = useAuth();
  const [coberturas, setCoberturas] = useState([]);
  
  // Obtener tipos de cobterturas
  useEffect(() => {
    const data = {
        codemp: EmpCodPlanes,
        tp: ProTipCodPlanes,
        codtab: TabCodPlanes,
        codcel: TabCodCelPlanes,
        AseCod: AseCod,
      };
      client.post('/rest/getTiposCobertura', data)
      .then(response => {
        const tiposDeCoberturas = response.data.TiposCobertura.map(cobertura => ({
          CobDes: cobertura.TipoCobertura.CobDes,
          ProSetCobVal: cobertura.TipoCobertura.ProSetCobVal     
        }));
        setCoberturas(tiposDeCoberturas);
      })
      .catch(error => {
        console.error('Error al obtener tipos de cobertura:', error);
      });
  }, []);

  return coberturas;
}

export default useGetCoberturas;