import React from "react";
import '../stylesheets/AnimatedGif.css';

const AnimatedGif = ({ src, alt }) => {
    return <img 
            src={src} 
            alt={alt}
            className="animated-gif"
            />;
}

export default AnimatedGif;