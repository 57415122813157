import React from 'react';
import imageSrc from '../media/boton-flecha 1.svg';
import '../stylesheets/BotonFlecha.css'; 

function ButtonWithImage ( {idPlan, CamDinCod, CamDinVal, CamDinObl, 
                            isFile, handleClick, texto,
                            EmpCod, ProTipCod, TabCod, TabCodCel, 
                            setBodyCotizacion, setCampoLongSDT}) {

  const handleClickWrapper = () => {
    handleClick(idPlan, texto);
    setBodyCotizacion(EmpCod, ProTipCod, TabCod, TabCodCel);

    const newLongSDT = [{
      "CamDinCod": CamDinCod,
      "CamDinVal": CamDinVal,
      "CamDinObl": CamDinObl,
      "isFile": isFile
    }];
    const LongSDTAsString = JSON.stringify(newLongSDT);

    setCampoLongSDT(LongSDTAsString);
  };

  //console.log('Botón flecha: ', EmpCod, ProTipCod, TabCod, TabCodCel);

  return (
    <button className="estilo-boton" 
            onClick={handleClickWrapper}>
    <img className="boton-image" src={imageSrc} alt="flecha.svg" />
    </button>
  );
}

export default ButtonWithImage;
