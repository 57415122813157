import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import config from '../config';

/* 
    Crear un contexto para generar un token y obtener autorización
    para consumir los servicios en los demás componentes 
*/
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState([]);
  const [loadingToken, setLoadingToken] = useState(true);

  const generateToken = async () => {
    try {
      const data = {
        client_id: config.clientId,
        grant_type: config.grantType,
        scope: config.scope,
        username: config.username,
        password: config.password,
      };

      const formData = qs.stringify(data);

      const response = await client.post('/oauth/access_token', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      setToken(response.data.access_token);
      setLoadingToken(false); // Indicar que la carga del token ha finalizado
    
    } catch (error) {
      console.error('ERROR AL GENERAR TOKEN:', error);
      throw error;
    }
  };


/* 
    Se crea la variable client utilizando el token. 
    Luego esta variable se exporta (junto con el token y loadingToken) para utilizarlas
    en otros compoenntes.
*/
  const client = axios.create({
    baseURL: config.apiUrl,
    headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await generateToken();
      } catch (error) {
        console.error('Error al obtener/generar token:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <AuthContext.Provider value={{token, loadingToken, client}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};