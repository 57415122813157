import React, { useState } from 'react';
import '../stylesheets/FormularioMundoRenta.css';
import { useScreen } from './ScreenContext';


function PlanesMundoRenta({ tipoAlquiler }) {

  const { currentScreen } = useScreen();

  // Función para obtener la ruta de la imagen según plan
  const getTextoPlanMR = (tipoAlquiler) => {
        switch (tipoAlquiler) {
          case 'MONOAMBIENTE':
            return (
                <div>
                    {/* <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN A
                    </div> */}
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO___________________<b>$10.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO__________________<b>$600.000</b></p>
                        <p>ROBO GENERAL___________________________<b>$60.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO________________<b>$120.000</b></p>
                        <p>CRISTALES______________________________<b>$30.000</b></p>
                        <p>RESPONSABILIDAD CIVIL__________________<b>$300.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: &nbsp;                         $2.625
                        </div>
                    </div>
                </div>
            );
          case 'DEPARTAMENTO':
            return (
                <div>
                    {/* <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN B
                    </div> */}
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO ____________________ <b>$27.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO __________________ <b>$1.200.000</b></p>
                        <p>ROBO GENERAL ______________________________ <b>$90.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO _________________ <b>$180.000</b></p>
                        <p>CRISTALES ___________________________________ <b>$60.000</b></p>
                        <p>RESPONSABILIDAD CIVIL ___________________ <b>$6.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '150px' }}></span> $5.774
                        </div>
                    </div>
                </div>
            );
          case 'CASA 40 MTS':
            return (
                <div>
                    {/* <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN C
                    </div> */}
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO____________________<b>$45.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO__________________<b>$1.800.000</b></p>
                        <p>ROBO GENERAL_____________________________<b>$120.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO_________________<b>$240.000</b></p>
                        <p>CRISTALES___________________________________<b>$90.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$8.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '140px' }}></span> $8.841
                        </div>
                    </div>
                </div>
            );
          case 'CASA 60 MTS':
            return (
                <div>
                    {/* <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN D
                    </div> */}
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO____________________<b>$62.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO__________________<b>$2.400.000</b></p>
                        <p>ROBO GENERAL_____________________________<b>$150.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO__________________<b>300.000</b></p>
                        <p>CRISTALES__________________________________<b>$120.000</b></p>
                        <p>RESPONSABILIDAD CIVIL__________________<b>$10.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '130px' }}></span> $11.792
                        </div>
                    </div>
                </div>
            );
          case 'CASA/QUINTA 80 MTS':
            return (
                <div>
                    {/* <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN E
                    </div> */}
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO____________________<b>$80.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO__________________<b>$3.000.000</b></p>
                        <p>ROBO GENERAL_____________________________<b>$180.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO_________________<b>$360.000</b></p>
                        <p>CRISTALES__________________________________<b>$150.000</b></p>
                        <p>RESPONSABILIDAD CIVIL__________________<b>$12.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '130px' }}></span> $14.859
                        </div>
                    </div>
                </div>
            );
          default:
        }
    }

            
    // Obtener la ruta de la imagen
    const textoPlanMR = getTextoPlanMR(tipoAlquiler);

  return (
    <div>
         {textoPlanMR}
    </div>
  )
};

export default PlanesMundoRenta;
