import React, { useState, useEffect } from 'react';
import BotonRedSocial from './BotonRedSocial';
import '../stylesheets/PieDePagina.css';
import logoMultiseguria from '../media/logo-multiseguria-blanco.png'
import fbimg from '../media/fb.svg' 
import igimg from '../media/ig.svg'
import ytimg from '../media/youtube 1.svg'    

function PieDePagina () {

  const [isDesktop, setIsDesktop] = useState(false);

  // Definir el ancho mínimo para considerar como escritorio
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); 
    };

    // Llamar a la función inicialmente para establecer el estado correcto
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <footer className="piedepagina">
      <div className="textoIzquierda">
      {isDesktop &&
        <div className="parrafopdp">
            <p>Hola, somos Multiseguria.com y queremos ayudarte, </p>
            <p>te dejamos nuestras vías de comunicación:</p>
        </div>
        }
        {!(isDesktop) &&
        <div className="parrafopdp">
            Nuestras vías de contacto son:
        </div>
        }
        <p> contacto@multiseguria.com </p>
      
        <img
          src={logoMultiseguria}  // Reemplaza con la ruta correcta a tu imagen
          alt="logo Multiseguria"
          className="logoPieDePagina"
        />

      </div>
    <div className="textoDerecha">

      {isDesktop && // Mostrar solo si es escritorio 
        <div className="parrafopdp">
            <p>También podés seguirnos en nuestras </p>
            <p>redes sociales:</p>
        </div>
      }
        <p>
            <BotonRedSocial
                imagenSrc={fbimg}
                enlace="https://www.facebook.com/Multiseguria.com.ar"
                alt="Facebook"
            />
            <BotonRedSocial
                imagenSrc={igimg}
                enlace="https://www.instagram.com/multiseguria/"
                alt="Instagram"
            />
            <BotonRedSocial
                imagenSrc={ytimg}
                enlace="https://youtube.com/@multiseguria8406?si=F4syWekcDZNU69OR"
                alt="YouTube"
            />
        </p>
    </div>
    </footer>
  );
};

export default PieDePagina;