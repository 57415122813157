import React from "react";
import imageSrc from '../media/boton-flecha 1.svg';
import '../stylesheets/BotonFlecha.css'; 

function BtnFlechaInstrumentosMusicales({ handleClick, EmpCod, ProTipCod, 
    TabCod, TabCodCel, valorTotalMoviles, valorTotalFijos, condIVA,
    setBodyCotizacion, setCampoLongSDT }) {

    const handleClickWrapper = () => {
        handleClick();

        console.log('CondIVA:', condIVA);
        console.log('ValTotalMovil:', valorTotalMoviles);
        console.log('ValTotalFijo:', valorTotalFijos);

        const arrayLongSDT = [
            {
                CamDinCod: condIVA.CamDinCod,
                CamDinVal: condIVA.IteDinCod,
                CamDinObl: true,
                isFile: false
            },
            {
                CamDinCod: '243',
                CamDinVal: valorTotalMoviles,
                CamDinObl: true,
                isFile: false
            },
            {
                CamDinCod: '244',
                CamDinVal: valorTotalFijos, 
                CamDinObl: true,
                isFile: false
            }
        ];

        // Convertir el array a string JSON
        const stringLongSDT = JSON.stringify(arrayLongSDT);

        // Establecer el cuerpo de la cotización y el campo LongSDT
        setBodyCotizacion(EmpCod, ProTipCod, TabCod, TabCodCel);
        setCampoLongSDT(stringLongSDT);

        console.log('arrayLongSDT:', arrayLongSDT);
        console.log('stringLongSDT:', stringLongSDT);
    };

    return (
        <button className="estilo-boton" 
                onClick={handleClickWrapper}>
            <img className="boton-image" src={imageSrc} alt="flecha.svg" />
        </button>
    );
}

export default BtnFlechaInstrumentosMusicales;
