import React from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/RequisitosSeguro.css';



function RequisitosNegocio(){
  return(
    <div className='contenedor-principal-requisitos'>
      <div className='contenedor-encabezado-requisitos'>
        <Encabezado></Encabezado>
        </div>
        <div className='contenedor-texto-requisitos-negocio'>
            <div className='titulo-requisitos-seguro'>
                REQUISITOS PARA CONTRATAR EL SEGURO.
            </div>

            <p>i) En el caso que la vivienda se encuentre dentro de un Country o un Barrio Cerrado, el predio cuenta con Cerco
            Perimetral y Vigilancia Permanente (24hs) - quedando sin efecto los ítems b), c) y d)-.</p>
            <p>Los siniestros cuya ocurrencia o magnitud haya sido facilitada por el incumplimiento de cualquiera de las
            condiciones de seguridad enunciadas precedentemente, que son condición para la validez de cualquier
            obligación indemnizatoria descripta en esta cobertura, pueden resultar una disminución en la indemnización
            correspondiente o incluso quedar excluidos.</p>
            <p>EN CASO QUE NO CUMPLAS ALGUNO DE ESTOS REQUISITOS COMUNICATE CON NOSOTROS. REALIZAREMOS
            UN SEGUIMIENTO PERSONALIZADO DE TU SITUACIÓN.</p>



        </div> 
        <PieDePagina></PieDePagina>
    </div>
  );
}

export default RequisitosNegocio;