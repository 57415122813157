import React, { useEffect, useState } from 'react';
import '../stylesheets/ListaTipoSeguro.css';
import TipoSeguroBtn from './TipoSeguro.js';
import { useScreen } from './ScreenContext';
import useObtenerSeguros from '../utils/ServiceGetSeguros.js'; // Importa el hook

function ListaTipoSeguro({ handleClick, setSeguroElegido }) {
  const seguros = useObtenerSeguros(); // Usa el hook directamente
  const {currentScreen} = useScreen();
  const [selectedId, setSelectedId] = useState(null);

  const handleClickAsesoria = (id, nombreProducto) => {
    setSelectedId(id === selectedId ? null : id);
    console.log('Producto seleccionado: ', nombreProducto)
  };

  return (
    <div className='lista-tipo-seguro'>
      {seguros.map((seguro) => (
        <div key={seguro.id} className='tipo-seguro-item'>
          <TipoSeguroBtn
            id={seguro.id}
            nombreSeguro={seguro.nombreProducto}
            iconoSeguro={seguro.icono}
            EmpCod={seguro.EmpCod}
            ProTipCod={seguro.ProTipCod}
            TabCod={seguro.TabCod}
            TabCodCel={seguro.TabCodCel}
            AseCod={seguro.AseCod}
            handleClick={currentScreen==='Asesoria' ? 
                        ()=> handleClickAsesoria(seguro.id, seguro.nombreProducto) : handleClick} 
            //handleClick={handleClick}
            setSeguroElegido={setSeguroElegido}
            isClicked={seguro.id === selectedId}
          />
        </div>
      ))}
    </div>
  );
}

export default ListaTipoSeguro;
