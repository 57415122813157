import React from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/RequisitosSeguro.css';



function RequisitosSeguro(){
  return(
    <div className='contenedor-principal-requisitos'>
      <div className='contenedor-encabezado-requisitos'>
        <Encabezado></Encabezado>
        </div>
        <div className='contenedor-texto-requisitos'>
            <div className='titulo-requisitos-seguro'>
                REQUISITOS PARA CONTRATAR EL SEGURO.
            </div>
            <div className='subtitulo-requisitos-seguro'>
                Declaro que la propiedad a asegurar cumple con la siguientes características:
            </div>

            <p>a) Materiales de construcción</p> 
            <p>Paredes: De material (ladrillo, piedra, y/o cemento) o un porcentaje hasta el 30% de materiales mixtos con madera.</p>
            <p>Techo: Sólidos (de azotea, cemento, pizarra, chapa, tejas, materiales equiparados a los mismos, permitiéndose una estructura portante de madera).</p>
            <p></p>

            <p>b) Matafuegos</p>
            <p>Cuenta con un extintor de 5kg. de capacidad a base de polvo químico seco ABC, cada 200m2 o fracción de superficie.</p>
            <p>Cuenta con instalación a la red de gas firmada por un profesional habilitado o en caso que cuente con una instalación con cilindros de Gas Butano/Propano, los mismos deben almacenarse e instalarse fuera de la vivienda.</p>
            <p>El tendido de cañerías para la distribución del gas debe ser metálico.</p>
            <p>No se permiten instalaciones realizadas con tubos plásticos o de goma.</p>
            <p></p>

            <p>d) Calefacción Ambiente:</p>
            <p>* Para el caso de viviendas que cuenten con hogares a leña, los mismos deberán estar construidos en su totalidad en mampostería de ladrillo o piedra, incluso su chimenea.</p>
            <p>* En todos los casos los hogares deberán contar con una mampara mata chispas.</p>
            <p>* Para aquellas viviendas que cuenten con estufas de las denominadas salamandras o cocinas a leña, las chimeneas de las mismas serán metálicas y tanto las estufas, cocinas como chimeneas deberán estar perfectamente aisladas por medio de espacio libre o elementos adecuados, tanto respecto de las paredes como de los techos.</p>
            <p>* No se permite el depósito de leña en el interior de la vivienda.</p>
            <p>* Para el caso de viviendas que cuenten con tipo de calefacción a llama abierta, deben estar perfectamente aisladas por medio de espacio libre o elementos adecuados, tanto respecto de las paredes como de los techos.</p>

            <p>Quedan excluidos los siniestros cuya ocurrencia o magnitud haya sido facilitada por el incumplimiento de
            cualquiera de las condiciones de seguridad enunciadas precedentemente, que son condición para la
            validez de cualquier obligación indemnizatoria descripta en esta cobertura.</p>

            <p><b>MEDIDAS DE SEGURIDAD Y AGRAVACIONES DE RIESGO</b> del ramo Robo. Este seguro se contrata en virtud de la declaración efectuada por el Asegurado, relativa a que la vivienda donde se hallan los bienes asegurados tenga las siguientes características:</p>
            <p>a) Todas las puertas de acceso a la vivienda o las del edificio que dan a la calle, patio o jardines, cuentan con cerraduras de tipo “doble paleta” o “bidimensionales”.</p>
            <p>c) Cuenta con rejas de protección de hierro en todas las ventanas y puertas con paneles de vidrio, ubicadas en la planta baja que dan a la calle, patios o jardines que por su parte sean accesibles desde la calle (es decir que no lo impidan muros, cercos o rejas de 1,80 mts. como mínimo de altura).</p>
            <p>d) Está edificada de medianera a medianera y no linda con un terreno baldío, obra en construcción o edificio abandonado (salvo que cuente con muros, cercos o rejas de una altura mínima de 1,80 mts. que impidan todo acceso que no sea por la puerta de calle del edificio)</p>
            <p>e) La presente póliza cubre en/los Edificio/s y/o construcciones indicado/s en la póliza, con su/s ocupación/es allí especificada/s, compuesto/s de planta baja con o sin pisos altos y/o sótanos y/o entrepisos en base a las especificaciones detalladas.</p>
            <p>h) el inmueble objeto de este seguro no es utilizado con fines comerciales fuera del declarado y/o actividades industriales, ni tiene comunicación con edificaciones destinadas a tales actividades comerciales y/o industriales.</p>
            <p>i) En el caso que la vivienda se encuentre dentro de un Country o un Barrio Cerrado, el predio cuenta con Cerco Perimetral y Vigilancia Permanente (24hs) - quedando sin efecto los ítems b), c) y d)-.</p>
            <p></p>

            <p>Los siniestros cuya ocurrencia o magnitud haya sido facilitada por el incumplimiento de cualquiera de las condiciones de seguridad enunciadas precedentemente, que son condición para la validez de cualquier obligación indemnizatoria descripta en esta cobertura, pueden resultar una disminución en la indemnización correspondiente o incluso quedar excluidos.</p>

        </div> 
        <PieDePagina></PieDePagina>
    </div>
  );
}

export default RequisitosSeguro;