import '../stylesheets/Cotizador.css';
import ListaTipoSeguro from './ListaTipoSeguro';
import Titulo from './Titulo';
import { useState } from 'react';
import Paso2 from './Paso2';
import MostrarCotizacion from './Cotizacion';
import imageSrc from '../media/puntos 1.svg';
import imagenFlecha from '../media/boton-flecha 1.svg';
import { useScreen } from './ScreenContext';
import AsesoriaPlusDetalle from './AsesoriaPlusDetalle';
import '../stylesheets/BotonFlecha.css'; 


function Cotizador() {

  // variables de estado (token, paso, tipoSeguroElegido, y idSeguro) utilizando 'useState'.
  // Cada variable de estado tiene su valor actual y una función asociada  para actualizar ese valor. 

  const [paso, setPaso] = useState(1);
  const [tipoSeguroElegido, setTipoSeguroElegido] = useState(null);
  const [idSeguro, setIdTipoSeguro] = useState(null);
  const [EmpCod, setEmpCod] = useState(null);
  const [ProTipCod, setProTipCod] = useState(null);
  const [TabCod, setTabCod] = useState(null);
  const [TabCodCel, setTabCodCel] = useState(null);
  const [AseCod, setAseCod] = useState(null);
  const [seleccionado, setSeleccionado] = useState(null);

  // variables para planes
  const [EmpCodPlan, setEmpCodPlan] = useState(null);
  const [ProTipCodPlan, setProTipCodPlan] = useState(null);
  const [TabCodPlan, setTabCodPlan] = useState(null);
  const [TabCodCelPlan, setTabCodCelPlan] = useState(null);
  const [LongSDT, setLongSDT] = useState('');

  // Se obtiene valor actual de la variable global screen 
  const {currentScreen} = useScreen();

  // seguro anterior seleccionado
  const [idSeguroAnterior, setIdSeguroAnterior] = useState(null);


  function avanzarPaso(){
    setPaso(paso + 1);
    console.log('PASO Nro: ' + paso);
  }
 
  const SeguroSelected = (seleccionadoId) => {
      setSeleccionado(seleccionadoId);
  }

  function setSeguro(idSeguro, seguroElegido, iconoSeguro, 
    EmpCod, ProTipCod, TabCod, TabCodCel, AseCod){

    // para actualizar dos variables de estado en el componente,
    // más mensajes de consola para verificar los cambios realizados en las variables de estado
    // cuando el usuario elige un seguro y se deben actualizar estas variables
    setIdTipoSeguro(idSeguro);
    setTipoSeguroElegido(seguroElegido);
    setEmpCod(EmpCod);
    setProTipCod(ProTipCod);
    setTabCod(TabCod);
    setTabCodCel(TabCodCel);
    setAseCod(AseCod);
  }

  function setBodyCotizacion(EmpCod, ProTipCod, TabCod, TabCodCel){
    setEmpCodPlan(EmpCod);
    setProTipCodPlan(ProTipCod);
    setTabCodPlan(TabCod);
    setTabCodCelPlan(TabCodCel);
  }

  // se setea el campo LongSDT
  function setCampoLongSDT(LongSDTAsString){
    setLongSDT(LongSDTAsString);
  }

  // Volver atrás en los pasos del cotizador
  function volverAtras(){
    setPaso(paso-1);
    console.log(paso);
  }

  let titulo = '';
  let subtitulo = '';
  let contenido;

  // Modifico título dependiendo si es pantalla Inicio o Asesoría Plus
  if (currentScreen === 'Inicio' || currentScreen === 'Cotizador') {
    titulo = 'Nuestros seguros online';
    subtitulo = 'SELECCIONÁ EL TIPO DE SEGURO QUE BUSCÁS (1/3)';
  } else if (currentScreen === 'Asesoria') {
    titulo = 'CONOCE NUESTROS SEGUROS';
    subtitulo = '';
  }


  switch(paso){

// Pantalla pricipal Cotizador "Nuestros Seguros online"

case 1:
  if (currentScreen === 'Inicio' || currentScreen === 'Cotizador') {
    contenido = (
      <ListaTipoSeguro 
        handleClick={avanzarPaso} 
        setSeguroElegido={setSeguro}
      />
    );
    break;
  } else if (currentScreen === 'Asesoria') {
    contenido = (
      <>
        <ListaTipoSeguro 
          handleClick={SeguroSelected} 
          setSeguroElegido={setSeguro} 
        />
        {tipoSeguroElegido && (
                    <AsesoriaPlusDetalle 
                    tituloasesoriaplus={tipoSeguroElegido}
                    idTipoSeguro={idSeguro}
                    EmpCod={EmpCod}
                    ProTipCod={ProTipCod}
                    TabCod={TabCod}
                    TabCodCel={TabCodCel}
                    AseCod={AseCod}
                    handleClick={SeguroSelected}
                    setBodyCotizacion={setBodyCotizacion}
                  
          />
        )}
      </>
    );
    break;
  }
  
// Pantalla (2do paso) Tipo de seguro seleccionado 
// Muestra planes, inputs de texto

    case 2:
      contenido =  <Paso2 
                    idTipoSeguro={idSeguro}
                    nombreTipoSeguro={tipoSeguroElegido}
                    EmpCod={EmpCod}
                    ProTipCod={ProTipCod}
                    TabCod={TabCod}
                    TabCodCel={TabCodCel}
                    AseCod={AseCod}
                    handleClick={avanzarPaso}
                    paso={paso}
                    setBodyCotizacion={setBodyCotizacion}
                    setCampoLongSDT={setCampoLongSDT} 
                  />;
      break;
    
// Pantalla (3er paso) Información según plan de seguro escogido

      case 3:
        contenido = <MostrarCotizacion 
                      texto={tipoSeguroElegido}
                      idSeguro={idSeguro}
                      EmpCodPlanes={EmpCodPlan}
                      ProTipCodPlanes={ProTipCodPlan}
                      TabCodPlanes={TabCodPlan}
                      TabCodCelPlanes={TabCodCelPlan}
                      LongSDT={LongSDT}
                      AseCod={AseCod}
                      paso={paso}
                      handleVolverAtras={volverAtras}
                    />;
        break;
  }


  return (
      <div className='cotizador-ancho'>
        {paso === 1 ? (            
          <>
          <div className={currentScreen === 'Cotizador' ? 'vista-cotizacion' : 'Cotizador'}>
          <div className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'color-fondo-inicio' 
                          : 'color-fondo-asesoria'}>
            
            {/* Verificar en qué pantalla está ubicado el usuario
                para mostrar o no la onda de fondo en el cotizador */}
            <>
              {
                currentScreen !== 'Inicio' && currentScreen !== 'Asesoria' ? (
              
                <div className="imagen-fondo">
                  <div className='centrar-cotizador'>
                  <Titulo 
                    titulo={titulo}
                    subtitulo={subtitulo}
                  />
                  {contenido}
                  </div>
                </div>
                ) : (
                  <div>
                    <Titulo 
                      titulo={titulo}
                      subtitulo={subtitulo}
                    />
                    {contenido}
                  </div>
                )}
            </>
          </div>
          </div>
          </>
        ) : null}

        {paso === 2 ? (
          <>
          <div className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'vista-cotizacion' : 'Cotizador'}>
            <div className={(currentScreen === 'Cotizador') ? 'imagen-paso2-cotizacion' : 'imagen-paso2-cotizacion-enInicio'}>
              {contenido}
              <div className='contenedor-btn-back'>
                <button className="estilo-boton-volver" 
                  onClick={() => volverAtras()}>
                    <img className="boton-image-volver" src={imagenFlecha} alt="flecha.svg" />
                </button>
              </div>
            </div>
          </div>
          </>
        ) : null}

        {paso === 3 ? (
          <>
          <div className={currentScreen === 'Cotizador' ? 'vista-infoCotizacion' : 'Cotizador'}>
            <div className={(currentScreen === 'Cotizador') ? 'imagen-fondo-vista-cotizacion' : 'imagen-fondo-vista-cotizacion-enInicio'}>
              <div className="contenedor-imagen-puntos-cotizacion">
                  <img className="icono-puntos-cotizacion" src={imageSrc} alt="puntos" />
              </div>
              {contenido}
            </div>
          
          </div>
          </>
        ) : null}

      </div>
  );
}

export default Cotizador;
