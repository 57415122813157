import React from 'react';
import config from '../config';
import '../stylesheets/PopUp.css';
import cerrar from '../media/x.png';

function RecuperarPassPopUp({ handleClose }) {
  return (
    <div className="popup-contenedor">
      <div className="popup-recupero">
        <iframe
            className='popup-image'
            title="Backoffice"
            src={config.recoverPasswordUrl}
            width="400px"
            height="200px"
            frameBorder="0"
            scrolling="no"
            >
        </iframe>
        <div className="cerrarPopUp-btn" onClick={handleClose}>
            <img src={cerrar} alt="Ícono de Cierre" />
          </div>
      </div>
    </div>
  );
}

export default RecuperarPassPopUp;