import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';
import useGetParams from './ServiceGetParams';

const useGetCotizacion = (EmpCodPlanes, ProTipCodPlanes, 
  TabCodPlanes, TabCodCelPlanes, LongSDT) => {
  
  const [infoCotizacion, setInfoCotizacion] = useState([])
  const {token, loadingToken, client} = useAuth();
  const [error, setError] = useState(null); // Estado para manejar el error
  const bodyCotizarRest = useGetParams(EmpCodPlanes, ProTipCodPlanes, 
    TabCodPlanes, TabCodCelPlanes, LongSDT);
    const [loading, setLoading] = useState(true);


  // Obtener información sobre la cotización
  useEffect(() => {
    const fetchCotizacion = async () => {
      if (!bodyCotizarRest.EmpCod || !bodyCotizarRest.CotCod) {
        setError('Faltan parámetros necesarios para obtener la cotización');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const data = {
          codemp: bodyCotizarRest.EmpCod,
          codCot: bodyCotizarRest.CotCod
        };

        const response = await client.post('/rest/getCotizaciones', data);

        if (response.data?.Cotizaciones) {
						   
          setInfoCotizacion(response.data.Cotizaciones);
          setError(null);
        } else {
          setError('Respuesta inválida del servidor');
        }
      }

      catch (error) {
        console.error('Error al obtener cotización', error);

        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
          setError(`Error del servidor: ${error.response.status} - ${error.response.data}`);
        } else if (error.request) {
          console.error('Request data:', error.request);
          setError('No se recibió respuesta del servidor');
        } else {
          console.error('Error message:', error.message);
          setError(`Error en la solicitud: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCotizacion();
  }, [bodyCotizarRest]);

  return {infoCotizacion, loading, error };
}

export default useGetCotizacion;