// config.js
const config = {
    apiUrl: 'https://backoffice.multiseguria.com.ar:8443/Seguros',
    loginUrl:'https://backoffice.multiseguria.com.ar:8443/Seguros/servlet/com.segurostest.login',
    registroUrl:'https://backoffice.multiseguria.com.ar:8443/Seguros/servlet/com.segurostest.registeruser',
    recoverPasswordUrl: 'https://backoffice.multiseguria.com.ar:8443/Seguros/servlet/com.segurostest.frontend.recoverpasswordstep1',
    compraUrl: 'https://backoffice.multiseguria.com.ar:8443/Seguros/servlet/com.segurostest.frontend.compra',
    clientId: '5aa238e916e740b4aa154da97caa6b95',
    grantType: 'password',
    scope: 'FullControl',
    username: 'frontend',
    password: 'Front!end.2024',
  };
  
  export default config;