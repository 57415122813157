import React, { useState } from 'react';
import '../stylesheets/TipoPlan.css';
import PopUp from './PopUp';
import { useScreen } from './ScreenContext';
//Imagenes PopUp Planes
import BicicletaA from '../media/Multiseguria Groups 29.4-03.png';
import BicicletaB from '../media/Multiseguria Groups 29.4-04.png';
import BicicletaC from '../media/Multiseguria Groups 29.4-05.png';
import BicicletaD from '../media/Multiseguria Groups 29.4-06.png';
import BicicletaE from '../media/Multiseguria Groups 29.4-07.png';
import FoodTruck1 from '../media/Multiseguria Groups 29.4-08.png';
import FoodTruck2 from '../media/Multiseguria Groups 29.4-09.png';
import FoodTruck3 from '../media/Multiseguria Groups 29.4-10.png';
import AlquilerA from '../media/Multiseguria Groups 29.4-11.png';
import AlquilerB from '../media/Multiseguria Groups 29.4-12.png';
import AlquilerC from '../media/Multiseguria Groups 29.4-13.png';
import AlquilerD from '../media/Multiseguria Groups 29.4-14.png';
import AlquilerE from '../media/Multiseguria Groups 29.4-15.png';



function Button({ idPlan, handleClick, texto, EmpCod, ProTipCod, TabCod, TabCodCel, setBodyCotizacion }) {
  const { currentScreen } = useScreen();
  const [showPopUp, setShowPopUp] = useState(false); //controla visibilidad del PopUp

  const handleClickWrapper = () => {
    handleClick();
    setBodyCotizacion(EmpCod, ProTipCod, TabCod, TabCodCel);
    setShowPopUp(true); // Mostrar el PopUp al hacer clic en el botón
    //setScreen('Cotizacion');
  };
  

  const handleClosePopUp = () => {
    setShowPopUp(false); // Ocultar el PopUp al hacer clic en x
  };

  // Función para obtener la ruta de la imagen según seguro y plan
  const getImagen = (ProTipCod, texto) => {
    switch (ProTipCod) {
      case '10':
        // Lista de planes: INTEGRAL FOOD TRUCK
        switch (texto.toUpperCase()) {
          case 'PLAN 500':
            return FoodTruck1;
          case 'PLAN 750':
            return FoodTruck2;
          case 'PLAN 1000':
            return FoodTruck3;
          default:
        }
      case '12':
      // Lista de planes: ALQUILERES TEMPORARIOS
      switch (texto.toUpperCase()) {
        case 'MONOAMBIENTE':
          return AlquilerA;
        case 'CASA 40 MTS.':
          return AlquilerB;
        case 'CASA 60 MTS.':
          return AlquilerC;
        case 'CASA 75 MTS.':
          return AlquilerD;
        case 'CASA 115 MTS.':
          return AlquilerE;
        default:
      }
      case '13':
      // Lista de planes: BICICLETAS
        switch (texto.toUpperCase()) {
          case 'PLAN 400':
            return BicicletaA;
          case 'PLAN 595':
            return BicicletaB;
          case 'PLAN 790':
            return BicicletaC;
          case 'PLAN 995':
            return BicicletaD;
          case 'PLAN 1190':
            return BicicletaE;
          default:
          }
      default:
    }
  };

  /*console.log('Button.js', 'EmpCod: ', EmpCod, 'ProTipCod: ', ProTipCod, 
            'TabCod: ', TabCod, 'TabCodCel: ', TabCodCel);*/

            
    // Obtener la ruta de la imagen
    const imagenPopUp = getImagen(ProTipCod, texto);

  return (
    <div>
      {(currentScreen === 'Inicio' || currentScreen === 'Cotizador') && (
        <button
          className='tipo-plan-btn'
          onClick={handleClickWrapper}>
          {texto.toUpperCase()}
        </button>
      )}
      {currentScreen === 'Asesoria' && (
        <>
        <button
          className='tipo-plan-btn-asesoria-plus'
          onClick={handleClickWrapper}>
          {texto.toUpperCase()}
        </button>
        {showPopUp && <PopUp handleClose={handleClosePopUp} imgPopUp={imagenPopUp} />} 
      </>
      )}
    </div>
  )
}

export default Button;
