import React from 'react';
import '../stylesheets/PopUp.css';
import cerrar from '../media/x.png';

function PopUp({ handleClose, imgPopUp }) {
  return (
    <div className="popup-contenedor">
      <div className="popup">
        <img src={imgPopUp} alt="Imagen" className="popup-image" />
        <div className="cerrarPopUp-btn" onClick={handleClose}>
            <img src={cerrar} alt="Ícono de Cierre" />
          </div>
      </div>
    </div>
  );
}

export default PopUp;
