import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';

const useGetPlanes = (EmpCod, ProTipCod, TabCod, TabCodCel, AseCod) => {
  const {token, loadingToken, client} = useAuth();
  const [planes, setPlanes] = useState([]);

  useEffect(() => {
    const data = {
        codemp: EmpCod,
        tpro: ProTipCod,
        codTa: TabCod,
        codCel: TabCodCel,
        AseCod: AseCod
        };
    const obtenerPlanes = async () => {
      try {
        const response = await client.post('/rest/getOpciones', data);

        const tipoDePlanes = response.data.Opciones.map(item => ({
            AseCod: item.Opcion.AseCod,
            EmpCod: item.Opcion.EmpCod,
            ProSetDes: item.Opcion.ProSetDes,
            ProSetTabCodHij: item.Opcion.ProSetTabCodHij,
            ProSetTabDes: item.Opcion.ProSetTabDes,
            ProTipCod: item.Opcion.ProTipCod,
            TabCod: item.Opcion.TabCod,
            TabCodCel: item.Opcion.TabCodCel      
          }));

        setPlanes(tipoDePlanes);
      } catch (error) {
        console.error('Error al cargar planes:', error);
        throw error;
      }
    };

    if (!loadingToken) {
        obtenerPlanes();
      }

  }, [planes]);

  return planes;
};

export default useGetPlanes;
