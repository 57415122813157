import React from 'react';
import '../stylesheets/AsesoriaPlusDetalle.css'; 


function TextosAsesoria({ tituloasesoriaplus }) {
    switch (tituloasesoriaplus) {
        case 'INTEGRAL FOOD TRUCK':
            return (
            <React.Fragment>
              <div className='texto-AP'>
                <p>Conocé tu seguro. Acompañamos tu emprendimiento, te ayudamos a cuidarlo.</p>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'>
                  ¿<span style={{ fontSize: '25px' }}>P</span>OR QUÉ CONTRATAR UN SEGURO PARA MI FOOD TRUCK?
                </div>
                <div className='texto-AP'>
                  <p>Ahora que tenes un emprendimiento, protegerlo en caso de que pueda presentarse algún inconveniente.</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ CUBRE Y QUÉ NO CUBRE?
                </div>
                <div className='texto-AP'>
                  <p>Este seguro cubre:</p>
                  <p>Incendio contenido, robo valores cajón mostrador, seguro técnico (pc, notebook), </p>
                  <p>cristales y Responsabilidad civil comprensiva.</p><br></br>
                  <p>Este seguro NO cubre:</p>
                  <p>El trailer o vehículo en caso de robo, incendio o daños. </p>
                  <p>Podemos asegurar el trailer o vehículo por aparte</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'>
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ NECESITO PRESENTAR PARA CONTRATAR MI SEGURO?
                </div>
                <div className='texto-AP'>
                  <p>Fotografías del Food truck y de su contenido.</p>
                </div>
              </div>

              <div className='titulo-observaciones-AP'>
                MEDIDA DE LA PRESTACIÓN
              </div>
              <div className='observaciones-AP'>
                <p>A riesgo absoluto(<a href="https://youtu.be/3rn178EK_CM?si=r2Rf53GPwLIZ8AZb" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
              <div className='titulo-observaciones-AP'>
                FRANQUICIA
              </div>
              <div className='observaciones-AP'>
                <p>SI (<a href="https://youtu.be/kUDIZDmChLg?si=aJI_48FFEW0B7yAo" target="_blank" rel="noopener noreferrer">Video link</a>)</p><br></br>
              </div>
            </React.Fragment>
          );
        case 'TODO RIESGO DE ELECTRÓNICOS':
            return (
            <React.Fragment>
              <div className='texto-AP'>
                <p>Conocé tu seguro. Usá tus equipos electrónicos sin miedo, protegemos su valor</p>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>P</span>OR QUÉ CONTRATAR UN SEGURO PARA MIS EQUIPOS ELECTRÓNICOS?'
                </div>
                <div className='texto-AP'>
                  <p> En caso de robo, incendio o accidentes en tus equipos y sus accesorios. Podes contar con una indemnización o reposición </p>
                  <p> de tus electrónicos, así podrás usar tu cámara digital o de video, computadora portátil o fija, u otro sin preocuparte.</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ COSAS PUEDO ASEGURAR?            
                </div>
                <div className='texto-AP'>
                  <p>Computadora Fija, Computadora portátil, Reproductor Musical, Equipos de Sonido, </p>
                  <p>Cámaras de Fotografía, Equipos de video, entre otros.</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
              <div className='subtitulo-AP'> 
                ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ CUBRE Y QUÉ NO CUBRE?
              </div>
                <div className='texto-AP'>
                  <p>Este seguro cubre:</p>
                  <p>Robo, incendio, accidentes y daños por fallas en la tensión eléctrica.</p>
                  <p>Equipos móviles dentro de Argentina, incluso dentro de un vehículo este cerrado</p>
                  <p>y el equipo no esté a la vista.</p><br></br>
                  <p>Este seguro NO cubre:</p>
                  <p>Hurto - (<a href="https://youtu.be/tUnH9oy8oM8?si=YuqTK80PJl_iVVBL" target="_blank" rel="noopener noreferrer">video explicativo</a>)</p>
                  <p>Dolo o culpa grave - (<a href="https://youtu.be/S9Qyzb3i75Y?si=XT0Ot1WLaAqFcooo" target="_blank" rel="noopener noreferrer">video explicativo</a>)</p>
                </div>
              </div>

              <div className='titulo-observaciones-AP'>
                CULPA AGRABADA
              </div>
              <div className='observaciones-AP'>
                <p>(<a href="https://youtu.be/S9Qyzb3i75Y?si=7LFq6iAlHyTEGr2q" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
              <div className='titulo-observaciones-AP'>
                MEDIDA DE LA PRESTACIÓN
              </div>
              <div className='observaciones-AP'>
                <p>A riesgo absoluto(<a href="https://youtu.be/3rn178EK_CM?si=r2Rf53GPwLIZ8AZb" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
              <div className='titulo-observaciones-AP'>
                FRANQUICIA
              </div>
              <div className='observaciones-AP'>
                <p>SI (<a href="https://youtu.be/kUDIZDmChLg?si=aJI_48FFEW0B7yAo" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
            </React.Fragment>
          );
        case 'TODO RIESGO BICICLETAS':
            return (
            <React.Fragment>
              <div className='texto-AP'>
                <p>Te presentamos nuestro propuesta para asegurar bicicletas. </p>
                <p>Es tu medio de transporte, protegelo y protegete. </p>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>P</span>OR QUÉ CONTRATAR UN SEGURO PARA TU BICICLETA?
                </div>
                <div className='texto-AP'>
                  <p>Ante el caos de las grandes urbes, la bicicleta se ha instaurado como una solución de transporte regular. </p>
                  <p>Podemos protegerte para que puedas circular tranquilo. </p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ CUBRE Y QUÉ NO CUBRE?
                </div>
                <div className='texto-AP'>
                  <p>Este seguro cubre:</p>
                  <p>Robo de bicicletas en la calle o estando atada con un candado.</p>
                  <p>Robo de documentos personales, llaves de domicilio, cartera o bolso.</p>
                  <p>Daños en la bicicleta.</p>
                  <p>Accidentes personales, fracturas - Invalidez total o parcial - o muerte.</p>
                  <p>Responsabilidad Civil - Daños a terceros.</p><br></br>
                  <p>Este seguro NO cubre:</p>
                  <p>Abandono.</p>
                  <p>Hurto.</p>
                  <p>Uso laboral (ej: Rappi o Pedidos Ya).</p>
                  <p>Práctica profesional de deportes.</p>
                  <p>Más exclusiones</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ DOCUMENTACIÓN NECESITO PRESENTAR PARA CONTRATAR ESTE SEGURO?
                </div>
                <div className='texto-AP'>
                  <p>Fotografías de la Bicicleta donde se pueda identificar y ver sus características.</p>
                  <p>*Si tu bici excede el valor de presentados en estos planes, conectate que te podemos armar algo a medida</p>
                </div>
              </div>
            </React.Fragment>
          );
        case 'INSTRUMENTOS MUSICALES':
            return (
            <React.Fragment>
              <div className='texto-AP'>
                <p>Conocé tu seguro. Usá tus equipos e instrumentos sin miedo, protegemos su valor</p>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>P</span>OR QUÉ CONTRATAR UN SEGURO PARA TUS INSTRUMENTOS MUSICALES?
                </div>
                <div className='texto-AP'>
                  <p>Entendemos el valor que tienen los instrumentos para vos, por lo tanto, es importante protegerlos </p>
                  <p>en caso de robo o accidentes, sobre todo cuando estas viajando o llevándolos a otra locación, </p>
                  <p>por eso te ofrecemos una póliza que te cuide en caso de alguno de esos problemas.</p>
                  <p>Podes asegurar también los accesorios como amplificadores y pedaleras. </p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ CUBRE Y QUÉ NO CUBRE?
                </div>
                <div className='texto-AP'>
                <p>Este seguro cubre:</p>
                <p>Robo, Accidente e Incendio, estés en tu casa, en tu estudio, en un taller o en tránsito.</p><br></br>
                <p>Este seguro NO cubre:</p>
                <p>Cuerdas, daños electrónicos, modificaciones y fundas.</p>
                <p>Hurto - (<a href="https://youtu.be/tUnH9oy8oM8?si=YuqTK80PJl_iVVBL" target="_blank" rel="noopener noreferrer">video explicativo</a>)</p>
                <p>Dolo o culpa grave - (<a href="https://youtu.be/S9Qyzb3i75Y?si=7LFq6iAlHyTEGr2q" target="_blank" rel="noopener noreferrer">video explicativo</a>)</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>E</span>N DÓNDE FUNCIONA MI SEGURO?
                </div>
                <div className='texto-AP'>
                  <p>Puedes contratar un Seguro que te cubra en todo el territorio argentino, o también si viajas a los países limítrofes. </p>
                  <p>Si tu intención es viajar por todo el mundo y tener tu instrumento asegurado, tenemos una póliza que te cubre en todo el mundo.</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ NECESITO PRESENTAR PARA CONTRATAR MI SEGURO?
                </div>
                <div className='texto-AP'>
                  <p>Deberás presentar los siguientes datos de tu instrumento:</p>
                  <p>Marca , Modelo, Año, Número de serie, Valor en el mercado; </p>
                  <p>en caso de ser un instrumento ARTESANAL debes adjuntar la cotización de un Luthier.</p>
                </div>
              </div>

              <div className='titulo-observaciones-AP'>
                CULPA AGRABADA
              </div>
              <div className='observaciones-AP'>
                <p>(<a href="https://youtu.be/S9Qyzb3i75Y?si=7LFq6iAlHyTEGr2q" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
              <div className='titulo-observaciones-AP'>
                MEDIDA DE LA PRESTACIÓN
              </div>
              <div className='observaciones-AP'>
                <p>A riesgo absoluto(<a href="https://youtu.be/3rn178EK_CM?si=r2Rf53GPwLIZ8AZb" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
              <div className='titulo-observaciones-AP'>
                FRANQUICIA
              </div>
              <div className='observaciones-AP'>
                <p>SI (<a href="https://youtu.be/kUDIZDmChLg?si=aJI_48FFEW0B7yAo" target="_blank" rel="noopener noreferrer">Video link</a>)</p>
              </div>
            </React.Fragment>
          );
        case 'ALQUILERES TEMPORARIOS':
            return (
            <React.Fragment>
              <div className='texto-AP'>
                <p>Tu inmueble requiere una protección diseñada para esa actividad</p>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>P</span>OR QUÉ CONTRATAR UN SEGURO PARA  MI INMUEBLE DE ALQUILER TEMPORARIO?
                </div>
                <div className='texto-AP'>
                  <p>Porque te brinda tranquilidad en caso de que algo salga mal, así puedes proteger tu patrimonio y fuente de ingresos</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ CUBRE Y QUÉ NO CUBRE?
                </div>
                <div className='texto-AP'>
                  <p>Un seguro especializado para tu inmueble ya sea casa, cabaña o departamento, cubre:</p>
                  <p>Incendio del edificio y su contenido.</p>
                  <p>Daños por granizo y HCVT*(Huracán, ciclón, vendaval o tornado)</p>
                  <p>Robo del contenido </p>
                  <p>Daño o pérdida de equipos electrónicos</p>
                  <p>Cristales.</p>
                  <p>Responsabilidad Civil: Daños físicos y materiales que pueda sufrir un inquilino dentro </p>
                  <p>del inmueble, linderos, suministro de alimentos, tenencia de animales domésticos </p>
                  <p>y piletas con la condición que tenga un cerco perimetral.</p><br></br>
                  <p>Este seguro NO cubre:</p>
                  <p>Los bienes de pasajeros por robo o hurto.</p>
                  <p>Terremotos</p>
                  <p>Depreciación, desgaste o deterioro de electrónicos por uso.</p>
                  <p>Piezas vítreas que no estén en posición vertical (techos o valero)</p>
                </div>
              </div>

              <div className='contenedor-titulo-y-texto'>
                <div className='subtitulo-AP'> 
                  ¿<span style={{ fontSize: '25px' }}>Q</span>UÉ DOCUMENTACIÓN NECESITO PRESENTAR PARA CONTRATAR ESTE SEGURO?
                </div>
                <div className='texto-AP'>
                  <p>Fotografías del inmueble y su contenido a asegurar.</p>
                  <p>Cumplir con las normas de suscripción:</p>
                  <p>Materialidad de la construcción: Ladrillo, piedra y/o cemento. </p>
                  <p>Mixta (Madera o chapa hasta 50%) Sujeto a aceptación de la empresa</p>
                  <p>Contar con sistemas de seguridad, cerraduras doble paleta o bidimensionales, rejas en todas las ventanas, </p>
                  <p>muros de 1.80 de alto mínimo, vigilancia o alarmas.</p>
                  <p>(Normas de Suscripción completas) </p>
                  <p>En caso de no cumplir con alguno de estos requisitos comuniquese para una asesoría personalizada</p><br></br>
                </div>
              </div>
            </React.Fragment>
          );            
        default:
            return null;
        }
      }
      
export default TextosAsesoria;