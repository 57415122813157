import React from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/Nosotros.css';
import Meridional from '../media/meridional.svg'
import Provincia from '../media/provincia.svg'
import Mapfre from '../media/mapfre.svg'

const Circulo = ({ texto, claseCirculo }) => {
    return (
      <div className={`circulo ${claseCirculo}`}>
      <span className="texto-circulo">{texto}</span>
      </div>
    );
  };

function Nosotros(){
  return(
    <div className='contenedor-principal-nosotros'>
      <div className='contenedor-encabezado-nosotros'>
        <Encabezado></Encabezado>
        </div>
        <div className='contenedor-nosotros'>
                <h1 className='texto-nosotros'>
                    Sobre nosotros
                </h1>
            <div className='contenedor-circulos'>
                <Circulo 
                    texto="Somos productores de Seguros con más de 25 años de experiencia." 
                    claseCirculo="circulo1" />
                <Circulo 
                    texto="Trabajamos sólo con empresas líderes en el mercado." 
                    claseCirculo="circulo2" />
                <Circulo 
                    texto="Nuestra prioridad es tu bienestar. Nos capacitamos constantemente para darte las mejores soluciones." 
                    claseCirculo="circulo3" />
            </div>
      </div>
      <div className='contenedor-empresas'>
        <img 
            src={Meridional} alt='Imagen Meridional'
            className='imagenes-empresas-1'
        />
        <img 
            src={Provincia} alt='Imagen Provincia Seguros'
            className='imagenes-empresas-2'
        />
        <img 
            src={Mapfre} alt='Imagen Mapfre'
            className='imagenes-empresas-3'
        />
      </div>
      <PieDePagina></PieDePagina>
    </div>
  );
}

export default Nosotros;