// TextInput.js
import React from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/InputTexto.css';


const TextInput = ({ value, onChange, placeholder}) => {

  const InputEstilo = isNaN(value) || Number(value) <= 0 ? 'estilo-input estilo-input-error' : 'estilo-input';


  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={InputEstilo}
    />
  );
};

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

export default TextInput;